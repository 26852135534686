<script>
import LinkBox from '../components/LinkBox.vue';

export default {
  components: {
    LinkBox,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      multipleSlides: false,
      canScrollLeft: false,
      canScrollRight: false,
    };
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
    readMoreLink() {
      // eslint-disable-next-line camelcase
      return this.block.fields.CompositeLink_ReadMore;
    },
    links() {
      // eslint-disable-next-line camelcase
      return this.block.fields.CompositeLink_Links;
    },
  },
  methods: {
    addIntersectionObserver() {
      const slides = this.container.children;
      const options = {
        root: this.container,
        threshold: 1,
      };

      const callback = () => {
        this.canScrollLeft = this.container.scrollLeft > 0;
        this.canScrollRight = this.container.scrollLeft < this.container.scrollWidth
                                                        - this.container.clientWidth;
      };

      this.observer = new IntersectionObserver(callback, options);

      for (let element of slides) {
        this.observer.observe(element);
      }
    },
    slideDistance() {
      return this.container.scrollWidth / this.container.children.length;
    },
    goPrev() {
      if (this.canScrollLeft) {
        this.container.scrollLeft -= this.slideDistance();
      }
    },
    goNext() {
      if (this.canScrollRight) {
        this.container.scrollLeft += this.slideDistance();
      }
    },
  },
  mounted() {
    this.container = this.$refs.container;
    this.addIntersectionObserver();
    this.multipleSlides = this.container.children.length > 1;
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
};
</script>

<template>
  <div class="related-links__container">
    <div
      class="related-links__read-more-container"
      v-if="readMoreLink"
    >
      <div class="related-links__read-more-text">
        {{ $globalTexts.global__read_more }}
      </div>
      <link-box
        class="related-links__read-more-image"
        :link="readMoreLink"
      />
    </div>
    <div class="related-links__title-and-links">
      <component :is="heading" v-if="title"
        class="related-links__title"
      >
        {{title}}
      </component>
      <div class="related-links__link-container" ref="container">
        <link-box
          v-for="(link, idx) in links"
          :key="idx"
          :link="link"
        />
      </div>
      <div
        v-if="multipleSlides"
        class="related-links__navigation"
        ref="navigation"
      >
        <div
          @click="goPrev"
          class="related-links__navigation-prev"
          :style="{opacity: canScrollLeft ? 1 : 0,
          pointerEvents: canScrollLeft ? 'auto' : 'none'}"
        >
        </div>
        <div
          @click="goNext"
          class="related-links__navigation-next"
          :style="{opacity: canScrollRight ? 1 : 0,
          pointerEvents: canScrollRight ? 'auto' : 'none'}"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .related-links__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 0;
    overflow: hidden;
  }

  .related-links__title-and-links {
    margin-top: 60px;
  }

  .related-links__title {
    text-align: left;
    color: var(--color-black);
    margin-bottom: 14px;
    line-height: 32px;
    margin-left: 10px;
  }

  .related-links__read-more-container {
    margin-top: 16px;
    width: 120px;
    margin-left: 10px;
  }

  .related-links__read-more-text {
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    user-select: none;
    cursor: default;
    margin-bottom: 10px;
    color: var(--color-black);
  }

  .related-links__link-container{
    display: flex;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 10px;
    gap: 16px;
  }

  .related-links__link-container::-webkit-scrollbar {
    display: none; /*hide scroll for Chrome, Safari, and Opera */
  }

  .related-links__navigation {
    display: flex;
    margin: 25px auto 10px;
    padding: 0 25px;
  }

  .related-links__navigation-prev {
    transform: rotate(-180deg);
    margin-right: auto;
  }

  .related-links__navigation-prev,
  .related-links__navigation-next {
    background-image: url('../static/icons/arrow_right_black_icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: opacity 0.1s linear;
  }

  @media (--desktop) {
    .related-links__container {
      display: flex;
    }

    .related-links__title-and-links {
      margin-left: auto;
      margin-top: 0;
      padding-right: 10px;
      padding-left: 10px;
    }

    .related-links__title {
      margin-left: 0;
    }

    .related-links__link-container{
      width: 836px;
      gap: 25px;
      padding: 0;
    }
  }

  @media (--tabletAndDesktop) {
    .related-links__title {
      margin-bottom: 16px;
    }
  }

  /* LinkBox component styling */

  .related-links__link-container .link-box__container {
    width: 74%;
    scroll-snap-align: center;
  }

  .related-links__read-more-container .link-box__image-container {
    margin-bottom: 8px;
  }

  .related-links__read-more-container .link-box__image-spacer {
    padding-top: 110%;
  }

  .related-links__read-more-container .link-box__title {
    font-size: 12px;
    line-height: 14px;
  }

  .related-links__read-more-container .link-box__subtitle {
    display: none;
  }

  .related-links__link-container .link-box__image-spacer {
    padding-top: 100%;
  }

  @media (--phone) {
    .related-links__link-container .link-box__image-container,
    .related-links__read-more-container .link-box__image-container{
      margin-top: 0;
    }
  }

  @media (--tabletAndDesktop) {
    .related-links__link-container .link-box__image-spacer {
      padding-top: 66.795%;
    }

    .related-links__link-container .link-box__container {
      width: 262px;
    }
  }
</style>
